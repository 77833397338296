import React from 'react'
import '../styles/footer.css';

export default function Footer() {
    return (
        <div>
            <footer className="footer">
                <ul className="socials">
                    <li className="social">
                        <a href="https://github.com/evanj354"><i className="fab fa-github"></i></a>
                    </li>
                    <li className="social">
                        <a href="https://www.linkedin.com/in/evjohnson1/"><i className="fab fa-linkedin"></i></a>
                    </li> 
                    <li className="social">
                        <a href="mailto:ej9606@gmail.com?subject=dslkjf&body=hello"><i className="far fa-envelope"></i></a>
                    </li>
                </ul>
                <div className="copyright">© Evan Johnson 2020</div>
            </footer>
        </div>
    )
}
