import PropTypes from "prop-types"
import React, { useEffect } from "react"
import '../styles/global.css';
import { useState } from 'react';


const Header = ({ siteTitle }) => {
  const [navVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  }

  useEffect(() => {
    window.addEventListener('scroll', () => setNavVisible(false));
  }, []);

  const handleClick = (urlAppend) => {
    setNavVisible(false);
    setTimeout(() => {
      window.history.replaceState('', document.title, "/");
    }, 5);
  }

  return (
  <header>
      <div onClick={() => toggleNav()} className="nav-toggle" aria-label="toggle navigation">
        <div className={navVisible?"nav-animate":""}>
          <div className="hamburger"><i className="fas fa-bars fa-2x"></i></div>
        </div>
      </div>
      <nav className="nav" style={navVisible ? navOpened : navClosed}>
        <ul className="nav__list">
          <li onClick={() => handleClick("about")} className="nav__item"><a href="#about" className="nav__link">About</a></li>
          <li onClick={() => handleClick("skills")} className="nav__item"><a href="#skills" className="nav__link">Skills</a></li>
          <li onClick={() => handleClick("projects")} className="nav__item"><a href="#projects" className="nav__link">Projects</a></li>
          <li onClick={() => setNavVisible(false)} className="nav__item"><a href={require("./media/Resume.pdf")} className="nav__link">Resume</a></li>
        </ul>
      </nav>
    </header>
    )
  }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const navOpened = {
  transform: 'translateX(0%)'
}

const navClosed = {

}

export default Header
